import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import * as Constants from '../components/Constants'
import Loader from "../components/Loader"

const News = () => {
  const {t} = useTranslation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/articles`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setArticles(res.data.articles);
        }
      }).catch();
  }, []);



  let viewArticles = '';

  viewArticles = articles.map((item, idx) => {
    let updatedHtmlContent = '';
    if(item.description != null){
      updatedHtmlContent = item.description.split(' ').slice(0, 12).join(' ');
    }     
      return(
        
          <Link key={idx} to={`${item.slug}`} className="articlesItem">
            <div className="articlesItemImgWrap">
                <img src={`${Constants.LARAVEL_URL}/assets/images/articles/${item.image}`} alt={item.title} className="articlesItemImg" />
            </div>
            <div className="articlesItemContent">
                <h4>{item.title}</h4>
                <div className='articlesItemContentText' dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}></div>
            </div>
          </Link>
      )
  });

  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('news_page_title')}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li>{t('news_page_title')}</li>
          </ul>
        </div>
        <div className='serviceContent'>
          {loading ? <Loader width={400} height={400} /> : viewArticles}
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default News