import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import * as Constants from '../components/Constants'
import Loader from "../components/Loader"

const Services = () => {
  const {t} = useTranslation();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/services`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setServices(res.data.services);
        }
      }).catch();
  }, []);

  let viewServices = '';

  viewServices = services.map((item, idx) => {
      return(
          <Link key={idx} to={`${item.slug}`} className="serviceItem">
            <img src={`${Constants.LARAVEL_URL}/assets/images/services/${item.image}`} alt={item.title} className="serviceItemImg" />
            <h3>{item.title}</h3>
          </Link>
      )
  });


  return (
    <>
    <main>
      <Header />
    <div className="container">
      <h1 className="commonTitle">{t('service_page_title')}</h1>
      <div className="commonBreadcrumbs">
        <ul>
            <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
            /
            <li>{t('service_page_title')}</li>
        </ul>
      </div>
      <div className='serviceContent'>
          {loading ? <Loader width={400} height={400} /> : viewServices}
      </div>
    </div>
    </main>
    <Footer />
  </>
  )
}

export default Services