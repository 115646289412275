import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import * as Constants from '../components/Constants'
import Loader from "../components/Loader"

const Offers = () => {
  const {t} = useTranslation();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/offers`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setOffers(res.data.offers);
        }
      }).catch();
  }, []);

  let viewOffers = '';

  viewOffers = offers.map((item, idx) => {
      return(
          <Link key={idx} to={`${item.slug}`} className="serviceItem">
            <img src={`${Constants.LARAVEL_URL}/assets/images/offers/${item.image}`} alt={item.title} className="serviceItemImg" />
            <h3>{item.title}</h3>
          </Link>
      )
  });


  return (
    <>
      <main>
        <Header />
        <div className="container">
          <h1 className="commonTitle">{t('special_offers_title')}</h1>
          <div className="commonBreadcrumbs">
            <ul>
                <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
                /
                <li>{t('special_offers_title')}</li>
            </ul>
          </div>
          <div className='serviceContent'>
            {loading ? <Loader width={400} height={400} /> : viewOffers}
          </div>
        </div>
        
        </main>
      <Footer />
    </>
  )
}

export default Offers