import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import * as Constants from '../components/Constants';
import axios from "axios";
import Loader from "../components/Loader";

const SolutionCategory = () => {
  const {t} = useTranslation();
  const [solutions, setSolutions] = useState([]);
  const [solutioncat, setSolutionCat] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/solcategory/${params.solution_slug}`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setSolutions(res.data.solutions);
          setSolutionCat(res.data.solcategory);
        }
      }).catch();
  }, []);

  let viewSolutions = '';
  viewSolutions = solutions.map((item, idx) => {
    return(
        <Link key={idx} to={`/solutions/${params.solution_slug}/${item.slug}`} className="solutionItem">
          <img
              src={`${Constants.LARAVEL_URL}/assets/images/solutions/${item.image}`}
              alt={`${item.title}`}
              width="293"
              height="189"
              className="solutionItemImg"
            />
            <p>{`${item.title}`}</p>
        </Link>
    )
  });


  return (
    <>
    <main>
      <Header />
    <div className="container">
      <h1 className="commonTitle">{`${solutioncat}`}</h1>
      <div className="commonBreadcrumbs">
        <ul>
            <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
            /
            <li><Link to="/solutions">{t('solutions_page_title')}</Link></li>
            /
            <li>{`${solutioncat}`}</li>
        </ul>
      </div>
      <div className="solutionContent">
          {loading ? <Loader width={400} height={400} /> : viewSolutions} 
      </div>
    </div>
    </main>
    <Footer />
  </>
  )
}

export default SolutionCategory