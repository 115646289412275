import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import ScrollToTop from "./components/ScrollToTop";
import './i18n.js';
import axios from 'axios';


axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('i18nextLng') || 'ru';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop/>  
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
