import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import * as Constants from '../components/Constants';
import { useTranslation } from 'react-i18next';

const Licence = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('licence_page_title')}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li>{t('licence_page_title')}</li>
          </ul>
        </div>
        <div className='licenseContent'>
          <a href={`${Constants.LARAVEL_URL}/assets/images/license/1.jpg`} data-fancybox="group">
              <img src={`${Constants.LARAVEL_URL}/assets/images/license/1.jpg`} alt='' />
          </a>
          <a href={`${Constants.LARAVEL_URL}/assets/images/license/2.jpg`} data-fancybox="group">
              <img src={`${Constants.LARAVEL_URL}/assets/images/license/2.jpg`} alt='' />
          </a>
          <a href={`${Constants.LARAVEL_URL}/assets/images/license/3.jpg`} data-fancybox="group">
              <img src={`${Constants.LARAVEL_URL}/assets/images/license/3.jpg`} alt='' />
          </a>
          <a href={`${Constants.LARAVEL_URL}/assets/images/license/4.jpg`} data-fancybox="group">
              <img src={`${Constants.LARAVEL_URL}/assets/images/license/4.jpg`} alt='' />
          </a>
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default Licence