import React, { useEffect, useState } from 'react'
import * as Constants from '../components/Constants';
import axios from "axios";
import Loader from "../components/Loader";
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const OneOffer = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`${Constants.LARAVEL_API_URL}/offer/${params.offer_slug}`).then(res => {
        if (res.data.status === 200) {
          setLoading(false)
          setOffer(res.data.offer)
        }
    });
  }, [params]);
  
  const addPrefixToImagePaths = (html, prefix) => {
    if (typeof html !== 'string') {
      return html;
    }
    
    return html.replace(/<img[^>]+src="([^">]+)"/g, (match, p1) => {
      // Проверяем, если src уже содержит префикс, пропускаем его
      if (p1.startsWith('http') || p1.startsWith(prefix)) {
        return match;
      }
      // Добавляем префикс к src
      const newSrc = `${prefix}${p1}`;
      return match.replace(p1, newSrc);
    });
  };

  const HtmlWithPrefixedImages = ({ htmlContent, prefix }) => {
    const updatedHtmlContent = addPrefixToImagePaths(htmlContent, prefix);

    // Преобразуем обновленный HTML в JSX
    return <div dangerouslySetInnerHTML={{ __html: updatedHtmlContent }} />;
  };

  const prefix = Constants.LARAVEL_URL;

  const {t} = useTranslation();


  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{offer.title}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li><Link to="/offers">{t('special_offers_title')}</Link></li>
              /
              <li>{offer.title}</li>
          </ul>
        </div>
        <div className='brandsContent'  id="imgContainer">
          <HtmlWithPrefixedImages htmlContent={offer.description} prefix={prefix} />
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default OneOffer