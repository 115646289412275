import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect, useState } from "react";
import * as Constants from '../components/Constants';
import axios from "axios";
import Loader from "../components/Loader";
import { useTranslation } from 'react-i18next';

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/brands`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setBrands(res.data.brands);
        }
      }).catch();
  }, []);


  let viewBrand = '';

  viewBrand = brands.map((item, idx) => {
      return(
          <Link key={idx} to={`${item.slug}`} className="brandItem">
            <img src={`${Constants.LARAVEL_URL}/assets/images/brands/${item.image}`} alt="" />
            <h3>{item.name}</h3>
          </Link>
      )
  });
  const {t} = useTranslation();

  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('brands_page_title')}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li>{t('brands_page_title')}</li>
          </ul>
        </div>
        <div className='brandsContent'>
          {loading ? <Loader width={400} height={400} /> : viewBrand}
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default Brands