import React, { Component } from "react";
import Slider from "react-slick"
import * as Constants from './Constants'


export default class HomeSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <>          
            <Slider {...settings}>
                <div>
                  <img src={`${Constants.LARAVEL_URL}/assets/images/slider/slide1.jpg`} alt="" />
                </div>
                <div>
                  <img src={`${Constants.LARAVEL_URL}/assets/images/slider/slide2.jpg`} alt="" />
                </div>
                <div>
                  <img src={`${Constants.LARAVEL_URL}/assets/images/slider/slide3.jpg`} alt="" />
                </div>
                <div>
                  <img src={`${Constants.LARAVEL_URL}/assets/images/slider/slide4.jpg`} alt="" />
                </div>
                <div>
                  <img src={`${Constants.LARAVEL_URL}/assets/images/slider/slide5.jpg`} alt="" />
                </div>
            </Slider>
        </>
      );
    }
  }