import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: 'ru',
    returnObjects: true,
    resources: {
        ru: {
            translation: {
                slogan: 'Оптические технологии',
                about_page_title: 'О нас',
                licence_page_title: 'Лицензии и сертификаты',
                contacts_page_title: 'Контакты',
                catalog_page_title: 'Каталог товаров',
                submit_btn: 'Оставить <1 />заявку',
                brands_page_title: 'Бренды',
                brand_page_title: 'Бренд',
                projects_page_title: 'Проекты',
                news_page_title: 'Новости компании',
                solutions_page_title: 'Решения',
                service_page_title: 'Сервис и услуги',                
                go_to_section: 'Перейти в раздел',
                product_category_footer: 'Категории продукции',
                information_footer: 'Информация',
                copyright_footer: 'ВСЕ ПРАВА ЗАЩИЩЕНЫ ИНТЕРНЕТ КАТАЛОГ - ТОО “Оптические технологии” ',
                bests_in_numbers_title: 'ПРЕИМУЩЕСТВА В ЦИФРАХ',
                search_by_catalog: 'Поиск по каталогу...',
                special_offers_title: 'Специальные предложения',
                partners_homepage_title: 'Наши партнеры',
                your_order_success: 'Ваша заявка успешно отправлена!',
                your_order_error: 'Заявка не отправлена!',
                set_your_order_title: 'Получить техническую консультацию',
                distributor_slogan: 'Дистрибьютер и интегратор технологических решений',                
                send_request_btn: 'Получить',
                modal_name: 'Ваше имя',
                modal_phone: 'Ваш телефон',
                modal_email: 'Ваш email',
                modal_comment: 'Оставьте комментарий',
                footer_call_title: 'Позвонить',
                product_sku: 'Код продукта',
                product_brand: 'Производитель',
                product_request_title: 'Запросить <2 /> цену',
                product_description: 'Описание продукта',
                product_char: 'Характеристики продукта',
                product_info: 'Информация для заказа',
                search_result_by_word: 'Результаты поиска по слову',
                search_result:'Результаты поиска',
                price_request_btn: 'Отправить',
                price_request_title: 'Запросить цену',
            }
        },
        kz: {
            translation: {
                slogan: 'Оптикалык технологиясы',
                about_page_title: 'Біз туралы',
                licence_page_title: 'Лицензиялар мен сертификаттар',
                contacts_page_title: 'Контактілер',
                catalog_page_title: 'Каталог',
                submit_btn: 'Өтінішіңізді <1 />жіберіңіз',
                brands_page_title: 'Брендтер',
                brand_page_title: 'Бренд',
                projects_page_title: 'Жобалар',
                news_page_title: 'Жаңалықтар',
                solutions_page_title: 'Шешімдер',
                service_page_title: 'Қызмет және құралдар',
                go_to_section: 'Бөлімге өтіңіз',  
                product_category_footer: 'Өнім санаттары',
                information_footer: 'ақпарат',
                copyright_footer: 'БАРЛЫҚ ҚҰҚЫҚТАР ҚОРҒАЛҒАН ИНТЕРНЕТ КАТАЛОГЫ - «Оптикалық технологиялар» ЖШС',
                bests_in_numbers_title: 'САНДАРДАҒЫ АРТЫҚШЫЛЫҚТАР',
                search_by_catalog: 'Каталог іздеу...',
                special_offers_title: 'Арнайы ұсыныстар',
                partners_homepage_title: 'Біздің серіктестер',
                your_order_success: 'Өтінішіңіз сәтті жіберілді!',
                your_order_error: 'Өтінім жіберілмеді!',
                set_your_order_title: 'Техникалық кеңес алыңыз',
                distributor_slogan: 'Технологиялық шешімдердің дистрибьюторы және интеграторы',
                send_request_btn: 'Алу',
                modal_name: 'Сіздің атыңыз',
                modal_phone: 'Сіздің телефоныңыз',
                modal_email: 'Сіздің emailңыз',
                modal_comment: 'Пікір қалдырыңыз',
                footer_call_title: 'Қоңырау',
                product_sku: 'Өнім коды',
                product_brand: 'Өндіруші',
                product_request_title: 'Бағаны <2 /> сұрау',
                product_description: 'Өнім cипаттамасы',
                product_char: 'Өнім ерекшеліктері',
                product_info: 'Ақпарат',
                search_result_by_word: 'Сөз бойынша іздеу нәтижелері',
                search_result:'Іздеу нәтижесі',
                price_request_btn: 'Жіберу',
                price_request_title: 'Бағаны сұрау',
            }
        },
        en: {
            translation: {
                slogan: 'Optical technologies',
                about_page_title: 'About us',
                licence_page_title: 'Licenses and certificates',
                contacts_page_title: 'Contacts',
                catalog_page_title: 'Catalog',
                submit_btn: 'Submit your <1 />application',
                brands_page_title: 'Brands',
                projects_page_title: 'Projects',
                brand_page_title: 'Brand',
                news_page_title: 'Company news',
                solutions_page_title: 'Solutions',
                service_page_title: 'Service and facilities',
                go_to_section: 'Go to section',
                product_category_footer: 'Product categories',
                information_footer: 'Information',
                copyright_footer: 'ALL RIGHTS RESERVED INTERNET CATALOG - Optical Technologies LLP',
                bests_in_numbers_title: 'BENEFITS IN NUMBERS',
                search_by_catalog: 'Catalog search...',
                special_offers_title: 'Special offers',
                partners_homepage_title: 'Our partners',
                your_order_success: 'Your application has been successfully sent!',
                your_order_error: 'Request not sent!',
                set_your_order_title: 'Get technical advice',
                distributor_slogan: 'Distributor and integrator of technology solutions',
                send_request_btn: 'Get',
                modal_name: 'Your name',
                modal_phone: 'Your phone',
                modal_email: 'Your email',
                modal_comment: 'Leave a comment',
                footer_call_title: 'Call',
                product_sku: 'Code of product',
                product_brand: 'Manufacturer',
                product_request_title: 'Price<2 /> request',
                product_description: 'Product description',
                product_char: 'Product features',
                product_info: 'Ordering information',
                search_result_by_word: 'Search result by word',
                search_result:'Search result',
                price_request_btn: 'Send',
                price_request_title: 'Request a price',
            }
        },
    }
})