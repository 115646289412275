import React, { useEffect, useState } from "react";
import axios from "axios"
import { Link, NavLink, useParams } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Loader from "../components/Loader";
import * as Constants from '../components/Constants'
import { useTranslation } from 'react-i18next';


const Category = () => {
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {        
        axios.get(`${Constants.LARAVEL_API_URL}/catalog/${params.category_slug}`).then(res => {
            if (res.data.status === 200) {
                setLoading(false);
                setCategory(res.data.category);
            }
        });
    }, [params]);
    
    
    let getSubcategories = category.children && category.children.map((subcategory) => (
        <Link to={`/catalog/${category.slug}/${subcategory.slug}`} className="subcategoryItem" key={subcategory.id}>
            <figure>
                <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${subcategory.image}`} alt={subcategory.name} className="productItemImg" />
            </figure>
            {subcategory.name}
          </Link>
    ));
    const {t} = useTranslation();

    return (
    <>
        <main>
        <Header />
        <div className="container">
            <h1 className="commonTitle">{loading ? <Loader width={30} height={30} /> : category.name}</h1>
            <div className="commonBreadcrumbs">
                <ul>
                    <li><NavLink to="/"><i className="fa-solid fa-house-chimney"></i></NavLink></li>
                    /
                    <li><NavLink to="/catalog">{t('catalog_page_title')}</NavLink></li>
                    /
                    <li>{loading ? <Loader width={10} height={10} /> : category.name}</li>
                </ul>
            </div>
            <div className="categoryOutput">
                {loading ? <Loader width={400} height={400} /> : getSubcategories}
            </div>
        </div>
        </main>
        <Footer />
    </>
    )
}

export default Category