import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {  useParams } from 'react-router-dom';
import * as Constants from '../components/Constants';
import Loader from "../components/Loader";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const SearchResult = () => {
    const params = useParams();
    const [searchWord, setSearchWord] = useState('');
    const [products, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSearchWord(params.searchWord);
       
        axios.get(`${Constants.LARAVEL_API_URL}/searchresult/${params.searchWord}`).then(res => {
            if (res.data.status === 200) {
                setProduct(res.data.products);
                setLoading(false);
            } else if(res.data.status === 404){
                setLoading(false);
                setProduct([]);
            }
        });
    }, [ searchWord ]);

    let allProducts = products.map((product) => (
        <Link to={`/product/${product.slug}`} className="productItem">
          <figure>
              <img 
              src={`${Constants.LARAVEL_URL}/assets/images/products/${product.images}`} 
              alt={product.name} 
              className="productItemImg"
              />
              <h2>{product.name}</h2>
          </figure>
        </Link>
      ));

      const {t} = useTranslation();

  return (
    <>
    <main>
      <Header />
    <div className="container">
      <h1 className="commonTitle">{t('search_result_by_word')}: <strong>{params.searchWord}</strong></h1>
      <div className="commonBreadcrumbs">
        <ul>
            <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
            /
            <li>{t('search_result')}</li>
        </ul>
      </div>
      <div className='brandsContent'>
        {loading ? <Loader width={400} height={400} /> : allProducts}
      </div>
    </div>
    </main>
    <Footer />
  </>
  )
}

export default SearchResult;
