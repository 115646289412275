import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import * as Constants from '../components/Constants'
import { useTranslation } from 'react-i18next';


const Catalog = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/categories`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setCategories(res.data.categories);
        }
      }).catch();
  }, []);

  const {t} = useTranslation();

  return (
    <>
        <main>
        <Header />
        <div className="container">
            <h1 className="commonTitle">{t('catalog_page_title')}</h1>
            <div className="commonBreadcrumbs">
              <ul>
                  <li><NavLink to="/"><i className="fa-solid fa-house-chimney"></i></NavLink></li>
                  /
                  <li>{t('catalog_page_title')}</li>
              </ul>
            </div>
            <div className="catalogContent">
            {loading ? <Loader width={200} height={200} /> : categories.map((category)=>(
              <div className="catalogItem">
                <h3>{category.name}</h3>
                <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${category.image}`} alt={category.name} />
                <NavLink to={`/catalog/${category.slug}`} className="homeBestBtn">
                  {t('go_to_section')}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
        </main>
        <Footer />
    </>
  )
}

export default Catalog