import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import * as Constants from '../components/Constants';
import axios from "axios";
import Loader from "../components/Loader";

const Solutions = () => {
  const {t} = useTranslation();
  const [solcategories, setSolCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${Constants.LARAVEL_API_URL}/solcategories`).then(res => {
        if (res.data.status === 200) {
          setLoading(false);
          setSolCategories(res.data.solcategories);
        }
      }).catch();
  }, []);

  let viewSolCategories = '';
  viewSolCategories = solcategories.map((item, idx) => {
    return(
        <Link key={idx} to={`${item.slug}`} className="solutionItem">
          <img
              src={`${Constants.LARAVEL_URL}/assets/images/solcategories/${item.image}`}
              alt={`${item.title}`}
              width="293"
              height="189"
              className="solutionItemImg"
            />
            <p>{`${item.title}`}</p>
        </Link>
    )
  });


  return (
    <>
      <main>
        <Header />
      <div className="container">
        <h1 className="commonTitle">{t('solutions_page_title')}</h1>
        <div className="commonBreadcrumbs">
          <ul>
              <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
              /
              <li>{t('solutions_page_title')}</li>
          </ul>
        </div>
        <div className="solutionContent">
            {loading ? <Loader width={400} height={400} /> : viewSolCategories} 
        </div>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default Solutions