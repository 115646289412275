import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';



const Contacts =  () => {
  const {t} = useTranslation();
  
  return (
    <>
      <main>
        <Header />
        <div className="container">
          <h1 className="commonTitle">{t('contacts_page_title')}</h1>
          <div className="commonBreadcrumbs">
            <ul>
                <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
                /
                <li>{t('contacts_page_title')}</li>
            </ul>
          </div>
          <div className='contactsContent'>
            <div className="footerContactsWrap">
              <div className="footerContactItem">
                  <a href="tel:+77717595949"><i className="fas fa-phone-volume" aria-hidden="true"></i>+7 (771) 759-59-49 </a>
              </div>
              <div className="footerContactItem">
                  <a href="tel:+77272902098"><i className="fas fa-phone-volume" aria-hidden="true"></i>+7 (727) 290 20 98</a>
              </div>
              <div className="footerContactItem">
                  <a href="mailto:info@optech.kz"><i className="fas fa-envelope" aria-hidden="true"></i>info@optech.kz</a>
              </div>
              <div className="footerContactItem">
                  <p><i class="fas fa-clock"></i>Пн - Пт: 09:00 AM - 18:00</p>
              </div>
              <div className="footerContactItem">
                  <a href="https://go.2gis.com/aml96" target="_blank" rel="noreferrer"><i className="fas fa-map-marker-alt"></i>г. Алматы, ул. Кенесары хана, <br/>дом 83/1, 1 этаж, офис 1Б</a>
              </div>
            </div>
            <div className='mapGoogle'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2909.905153012988!2d76.87184546829647!3d43.16951504059889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836634e67b918b%3A0x2d6c28ef94c8137c!2z0YPQu9C40YbQsCDQmtC10L3QtdGB0LDRgNGLINCl0LDQvdCwIDQ2LCDQkNC70LzQsNGC0YsgMDUwMDcw!5e0!3m2!1sru!2skz!4v1714039215038!5m2!1sru!2skz" title='map' ></iframe>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}


export default Contacts