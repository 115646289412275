import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const About = () => {
  const {t} = useTranslation();

  return (
    <>
    <main>
      <Header />
        <div className="container">
          <h1 className="commonTitle">{t('about_page_title')}</h1>
          <div className="commonBreadcrumbs">
            <ul>
                <li><Link to="/"><i className="fa-solid fa-house-chimney"></i></Link></li>
                /
                <li>{t('about_page_title')}</li>
            </ul>
          </div>
          <div className="aboutContentWrap">
              <div className="homeBestAboutContent">
                <p>
                Компания ТОО «Оптические Технологии» — поставщик и интегратор технологических решений в области телекоммуникаций, энергетики и промышленной автоматизации. Компания реализует проекты для заказчиков, помогая предприятиям различных отраслей в Казахстане и странах Центральной Азии повысить операционную эффективность. Благодаря большому опыту и постоянному совершенствованию у компании сформировались отличительные преимущества, которые позволило стать официальным дистрибьютором ведущих мировых производителей.<br/><br/>
                ТОО «Оптические Технологии» сотрудничает с такими мировыми компаниями как Anritsu Corporation, Sumitomo Electric, Yokogawa Electric Corporation, Aaronia AG, Fluke, Rohde&Schwarz, Triathlon Batterien GmbH и тд.
                </p>
              </div>
              <div className="homeBestNumberItemWrap">              
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                    11
                  </div>
                  <p><strong>11 лет</strong> опыта в оптических <br/> технологиях и решениях</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  1000+
                  </div>
                  <p>Проданно свыше <strong>1000</strong><br/> оборудования</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  30+
                  </div>
                  <p>Более <strong>30</strong> постоянных<br/>клиентов и партнеров</p>
                </div>
                <div className="homeBestNumberItem">
                  <div className="homeBestNumberNum">
                  100+
                  </div>
                  <p>Более <strong>100</strong> Реализованных<br/> проектов</p>
                </div>
              </div>
          </div>
        </div>
        </main>
        <Footer />
      </>
  )
}

export default About